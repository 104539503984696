import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import toHtml from 'html-react-parser'
import * as usersService from '../../services/usermgmt.services';
import Breadcrums from '../../common/breadcrumbs';
import Loader from '../../common/loader'
import { decideRole, formateDate,  handleServerValidations, hasPermission, showStatus } from '../../../../utils/commonfunction';
import CustomPagination from '../../common/custompagination';
import $ from "jquery";
import { SWAL_SETTINGS, TRANSACTION_STATUS } from '../../../../utils/Constants';
import CustomRangepicker from '../../common/rangepicker';
import { Button, Modal } from 'rsuite';
import Swal from 'sweetalert2';


const UsersView = (props) => {
  const navigate = useNavigate()
  const [status] = useState([{ name: "Status", status__id: "" }, { name: "Success", status__id: TRANSACTION_STATUS.SUCCESS }, { name: "Failed", status__id: TRANSACTION_STATUS.FAILED }, { name: "Pending", status__id: TRANSACTION_STATUS.PENDING }, { name: "Refunded", status__id: TRANSACTION_STATUS.REFUNDED }]);
  const params = useParams();
  const [query] = useSearchParams();
  const [showdefault, setShowDefault] = useState({})
  const [loader, setLoader] = useState(true)
  const [datalength, setDataLength] = useState("")
  const [itemperpage] = useState(10)
  const [page, setPage] = useState(1);
  const [globalsearch, setGlobalSearch] = useState("");
  const [search, setSearch] = useState({});
  const [transactionsData, setTransactionsData] = useState([]);
  const [resetdate, setResetDate] = useState(false);
  const [list, setList] = useState([])
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [statsupdate, setStatusUpdate] = useState(false);
  const [chatDatalength, setChatDataLength] = useState("")
  const [chatItemperpage] = useState(10)
  const [chatPage, setChatPage] = useState(1);
  const [allMessage, setAllMessage] = useState([]);
  const [roomId, setRoomId] = useState([]);
  const breadcrumbs = [{ title: "Dashboard", url: "/admin/dashboard" }, { title: "Users", url: "/admin/user-management/users/list/1" }, { title: "View", url: "" },]


  useEffect(() => {
    usersService.Details(params.id).then(response => {
      setShowDefault(response && response.data ? response.data : [])
    }).catch(error => {
      console.log("error=====>", error)
    })
  }, [params.id])


  useEffect(() => {
    setLoader(true)
    const formData = new FormData()
    formData.append("page", query.get('page'))
    formData.append("per_page", itemperpage)
    formData.append("search", JSON.stringify(search));
    formData.append("global_search", globalsearch);
    formData.append("user_id", params.id);
    usersService.Transactions(formData).then(data => {
      setTransactionsData(data && data?.data && data?.data.list ? data.data.list : []);
      setDataLength(data.data.total_records)
      setPage(data && data.data && data.data.page ? data.data.page : 1);
      setLoader(false)

    }).catch(error => {
      console.log("Error:", error);
    });
  }, [search, globalsearch, page,itemperpage]);


  useEffect(() => {
    setLoader(true)
    const formData = new FormData()
    formData.append("page", query.get('chat_page'))
    formData.append("per_page", chatItemperpage)
    formData.append("user_id", params.id);
    usersService.ListChats(formData).then(data => {
      setList(data && data?.data && data?.data?.list ? data?.data?.list : []);
      setChatDataLength(data.data.total_records)
      setChatPage(data && data.data && data.data.page ? data.data.page : 1);
      setLoader(false)
    }).catch(error => {
      console.log("Error:", error);
    });
  }, [chatPage,statsupdate,chatItemperpage,params.id]);


  useEffect(() => {
    if(roomId){
      usersService.ChatDetails(roomId).then(response => {
        setAllMessage(response && response.data ? response.data : [])
      }).catch(error => {
        console.log("error=====>", error)
      })
    }
  }, [statsupdate,roomId]);

  const resetFilter = (e) => {
    e.preventDefault();
    setGlobalSearch("");
    prepareSearch();
    setSearch({});
    setResetDate(!resetdate);
    $("#defaultstatus")[0].selectedIndex = 0;
  };


  const prepareSearch = (key, value) => {
    let sr = { ...search };
    if (String(value).length > 0) {
      sr[key] = value;
    } else {
      delete sr[key];
    }
    setSearch(sr);
  };

  const getStatus = (status) => {
    if (status === TRANSACTION_STATUS.PENDING) {
      return <span className="badge badge-warning">Pending</span>;
    } else if (status === TRANSACTION_STATUS.SUCCESS) {
      return <span className="badge badge-success">Success</span>;
    } else if (status === TRANSACTION_STATUS.FAILED) {
      return <span className="badge badge-danger">Failed</span>;
    } else if (status === TRANSACTION_STATUS.REFUNDED) {
      return <span className="badge badge-info">Refunded</span>;
    }
  };

  const handleViewClick = (data) => {
    setRoomId(data?.room);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setRoomId("");
  };

  function Deletefunction(data) {
    Swal.fire({
      customClass: 'swal-wide',
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#403fad',
      cancelButtonColor: '#f1388b',
      confirmButtonText: 'Yes, delete it!'
    }).then((result) => {
      if (result.isConfirmed) {
        const formData = new FormData();
        formData.append("o_id", data)
        usersService.ChatDelete(formData).then(response => {
          setStatusUpdate(!statsupdate)
          if(allMessage?.length === 1){
            closeModal();
          }
          if (response.success) {
            Swal.fire({
              icon: 'success',
              text: response.message,
              ...SWAL_SETTINGS
            })
          } else {
            Swal.fire({
              icon: 'error',
              text: handleServerValidations(response),
              ...SWAL_SETTINGS
            })
          }
        }).catch(error => {
          console.log("deleteError", error)
        });
      }
    })
  }

  function isJson(data) {
    try {
      JSON.parse(data);
    } catch (e) {
      return false;
    }
    return true;
  }

  return (
    <>
      <Breadcrums data={breadcrumbs} />
      <div className="row row-sm">
        <div className="col-lg-12 col-md-12 animation_fade">
          <div className="card custom-card">
            <div className="card-body">
              <div>
                <h6 className="main-content-label mb-3">View User</h6>
              </div>
              {showdefault && Object.keys(showdefault).length > 0 ? <div className='row'>
                <div className="col-lg-12 form-group">
                  <table id="simple-table" className="table  table-bordered table-hover">
                    <tbody>
                      <tr>
                        <th>Image</th>
                        <td><img src={showdefault && showdefault.profile_image ? showdefault.profile_image : "N/A"} alt="profile" style={{ height: "100px" }} /></td>
                      </tr>
                      <tr>
                        <th>Title</th>
                        <td className='text-capitalize'>{showdefault && showdefault.title ? showdefault.title : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Salutation</th>
                        <td className='text-capitalize'>{showdefault && showdefault.gender ? showdefault.gender : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>First Name</th>
                        <td className='text-capitalize'>{showdefault && showdefault.first_name ? showdefault.first_name : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Middle Name</th>
                        <td className='text-capitalize'>{showdefault && showdefault.middle_name ? showdefault.middle_name : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Last Name</th>
                        <td className='text-capitalize'>{showdefault && showdefault.last_name ? showdefault.last_name : "N/A"}</td>
                      </tr>

                      <tr>
                        <th>Email</th>
                        <td>{showdefault && showdefault.email ? showdefault.email : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Role</th>
                        <td>{decideRole(showdefault.role)}</td>
                      </tr>

                      <tr>
                        <th>Status</th>
                        <td>{showdefault && showdefault.status === 0 ? toHtml(showStatus(showdefault.status)) : toHtml(showStatus(showdefault.status))}</td>
                      </tr>

                      <tr>
                        <th>Phone Number</th>
                        <td>{ showdefault && showdefault?.phone_number ? isJson(showdefault?.phone_number) ? JSON.parse( showdefault?.phone_number )?.number : showdefault?.phone_number?.number: "N/A"}
                         </td>
                      </tr>
                      <tr>
                        <th>Phone Number Work</th>
                          <td>{showdefault && showdefault?.phone_number_work ? isJson(showdefault?.phone_number_work) ? JSON.parse( showdefault?.phone_number_work )?.number : showdefault?.phone_number_work?.number: "N/A"}
                          </td>
                      </tr>
                      <tr>
                        <th>Function</th>
                        <td>{showdefault && showdefault?.event_function ? showdefault.event_function : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Employee Code</th>
                        <td>{showdefault && showdefault?.extra_info?.employee_code ? showdefault.extra_info?.employee_code : "N/A"}</td>
                        </tr>
                        <tr>
                        <th>Employee Number</th>
                        <td>{showdefault && showdefault?.extra_info?.employee_number ? showdefault.extra_info?.employee_number : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Created Date</th>
                        <td>{showdefault && showdefault.createdAt ? formateDate(showdefault.createdAt) : "N/A"}</td>
                      </tr>
                      {
                        showdefault?.createdBy ? 
                      <tr>
                        <th>Created By</th>
                        <td>{showdefault && showdefault.createdBy?.name ? showdefault.createdBy.name : "N/A"}</td>
                      </tr>
                      :null
                      }
                        {
                        showdefault?.updatedBy ? 
                      <tr>
                        <th>Updated By</th>
                        <td>{showdefault && showdefault.updatedBy?.name ? showdefault.updatedBy.name : "N/A"}</td>
                      </tr>
                      :null
                      }
                      <tr>
                        <th>Modified Date</th>
                        <td>{showdefault && showdefault.updatedAt ? formateDate(showdefault.updatedAt) : "N/A"}</td>
                      </tr>
                      <tr>
                        <th>Notes</th>
                        <td>{showdefault && showdefault.notes ? showdefault.notes : "N/A"}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div> : <Loader />}
            </div>
            <div className="">
              <div className="card custom-card">
                <div className="card-body">
                  <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h6 className="main-content-label">Transactions Details</h6>
                    <div className='d-flex align-items-center'>
                      <div className="form-group mb-0 me-3">
                        <div className="form-group mb-0 rangepicker_container filter_design">
                          <i className="fa fa-search calender_icon"></i>
                          <input
                            type="search"
                            className="form-control"
                            value={globalsearch}
                            placeholder="Search"
                            onChange={(e) => {
                              setGlobalSearch(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="me-3">
                        <CustomRangepicker
                          GetDateRange={(e) => {
                            prepareSearch("createdAt", e);
                          }}
                          resetdate={resetdate}
                        />
                      </div>
                      <div className="me-3">
                        <div className="form-group mb-0 filter_icon_container filter_design">
                          <i className="fa fa-filter mr-2 filter_icon"></i>
                          <div className="select-down-arrow">
                            <select className="form-control cp" onChange={(e) => { prepareSearch("status", e.target.value) }} id="defaultstatus">
                              {status && status.length > 0 && status.map((option, i) => {
                                return (
                                  <option key={i} value={option.status__id}>
                                    {option.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <button
                        type="reset"
                        value="Reset"
                        onClick={resetFilter}
                        className="btn btn-warning float-right mr-2"
                      >
                        Reset Filter
                      </button>
                    </div>
                  </div>
                  <div className="table-responsive position-relative">
                    <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                      <thead>
                        <tr>
                          <th>Paypal Id</th>
                          <th>Payer Id</th>
                          <th>Amount</th>
                          <th>Refund Type</th>
                          <th>Type</th>
                          <th>Created Date</th>
                          <th>Status</th>
                          <th className='action_head'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ?
                          <tr>
                            <td colSpan={8}><Loader /></td>
                          </tr> : <>
                            {transactionsData.length ? transactionsData.map((data, i) => {
                              return <tr key={i}>
                                <td>{data?.paypal_pay_id ? data?.paypal_pay_id : "N/A"}</td>
                                <td>{data?.payer_id ? data?.payer_id : "N/A"}</td>
                                <td>{data?.amount ? (data?.currency?.sign ? data.currency.sign : '') + data?.amount : "0.00"}</td>
                                <td className="text-capitalize">{data?.refund_type ? data.refund_type : "N/A"}</td>
                                <td className="text-capitalize">{data?.type ? data.type : "N/A"}</td>
                                <td>{data?.createdAt ? formateDate(data?.createdAt) : "N/A"}</td>
                                <td>{data?.status ? getStatus(data?.status) : "N/A"}</td>
                                <td>
                                  <div className="d-flex">
                                    <Link
                                      className="btn ripple btn-main-primary signbtn"
                                      to={`/admin/transactions/view/${data?._id}`}
                                    >
                                      View
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            }) : <tr><td colSpan={8} className="text-center">No records</td></tr>}
                          </>}
                      </tbody>
                    </table>
                  </div>
                  <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {datalength ? datalength : "0"}</b></div>
                  {datalength && datalength > 0 ?
                    <CustomPagination datalength={datalength} paramName={'page'} itemperpage={itemperpage} currentPage={page} setPage={setPage} query={"true"} pageRoute={[{ name: "Question", path: `/admin/user-management/users/view/${params.id}` }]} /> : ""}
                </div>
              </div>
            </div>
            <div className="">
              <div className="card custom-card">
                <div className="card-body">
                  <div className='d-flex justify-content-between align-items-center mb-4'>
                    <h6 className="main-content-label">Recent Chats</h6>
                    <div className='d-flex align-items-center'>
                    </div>
                  </div>
                  <div className="table-responsive position-relative">
                    <table className="table table-bordered border-t0 key-buttons text-nowrap w-100">
                      <thead>
                        <tr>
                          <th>Receiver Name</th>
                          <th>Receiver Email</th>
                          <th>Last Message</th>
                          <th>Last Chat Time</th>
                          <th className='action_head'>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loader ?
                          <tr>
                            <td colSpan={5}><Loader /></td>
                          </tr> : <>
                            {list.length ? list.map((item, i) => {
                              return <tr key={i}>
                                <td className="text-capitalize">{item?.receiver_data?.name ? item?.receiver_data?.name : "N/A"}</td>
                                <td>{item?.receiver_data?.email ? item?.receiver_data?.email : "N/A"}</td>
                                {item?.last_message?.type === "image" || item?.last_message?.type === "file" ? <td>{<Link to={item?.last_message?.file_url} target="_blank">{item?.last_message?.file_name}</Link>}</td> : <td>{item?.last_message?.message ? item?.last_message?.message : "N/A"}</td>}
                                <td>{item?.last_message?.createdAt ? formateDate(item.last_message.createdAt) : "N/A"}</td>
                                <td>
                                  <div className="d-flex">
                                    <button
                                      className="btn ripple btn-main-primary signbtn"
                                      onClick={() => handleViewClick(item)}
                                    >
                                      View
                                    </button>
                                  </div>
                                </td>
                              </tr>
                            }) : <tr><td colSpan={5} className="text-center">No records</td></tr>}
                          </>}
                      </tbody>
                    </table>
                    <Modal
                      size="lg"
                      className="invitation-modal modal-height-auto"
                      open={isModalVisible}
                      onClose={closeModal}
                    >
                      <Modal.Header>
                        <Modal.Title>Chats</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <table id="questions-table" className="table table-bordered table-hover">
                          <thead>
                            <tr>
                              <th>Chats</th>
                              <th>Sender Name</th>
                              <th>Receiver Name</th>
                              {hasPermission('/admin/user-management/users/view/chat/action') ?
                              <th className='action_head'>Actions</th>
                              :null}
                            </tr>
                          </thead>
                          <tbody>
                            {loader ?
                          <tr>
                            <td colSpan={5}><Loader /></td>
                          </tr> : <>
                            {allMessage.length ? allMessage.map((item, i) => {
                              return <tr key={i}>
                                {item?.type === "image" || item?.type === "file" ? <td>{<Link to={item?.file_url} target="_blank">{item?.file_name}</Link>}</td> : <td>{item?.message ? item?.message : "N/A"}</td>}
                               <td>{item?.sender[0]?.name ? <Link to={`/admin/user-management/users/list/1`}>{item.sender[0]?.name}</Link> : "N/A"}</td>
                               <td>{item?.receiver[0]?.name ? <Link to={`/admin/user-management/users/list/1`}>{item.receiver[0]?.name}</Link> : "N/A"}</td>
                                {hasPermission('/admin/user-management/users/view/chat/delete') ?
                                <td>
                                  <div className="d-flex">
                                    <button
                                      className="btn ripple btn-secondary mlAction"
                                      onClick={() => { Deletefunction(item._id) }}
                                    >
                                      Delete
                                    </button>
                                  </div>
                                </td>
                                :null}
                              </tr>
                            }) : <tr><td colSpan={5} className="text-center">No records</td></tr>}
                          </>}
                          </tbody>
                        </table>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button onClick={closeModal} appearance="subtle">
                          Close
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                  <div className="" id="example1_info" role="status" aria-live="polite"><b>Total Records : {chatDatalength ? chatDatalength : "0"}</b></div>
                  {chatDatalength && chatDatalength > 0 ?
                    <CustomPagination datalength={chatDatalength} paramName="chat_page" itemperpage={chatItemperpage} currentPage={chatPage} setPage={setChatPage} query={"true"} pageRoute={[{ name: "userManagement", path: `/admin/user-management/users/view/${params.id}` }]} /> : ""}
                </div>
              </div>
            </div>
            <div className="mt-5 back-button">
              <button className="btn ripple btn-dark" type='button' onClick={() => navigate('/admin/user-management/users/list/1')}>
                <i className="ace-icon-solid ion-arrow-return-left bigger-110 mx-1"></i>
                Back
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default UsersView